<script lang="ts" setup>
import AddCard from "../components/cards/AddCard.vue";
import CardsLayout from "../components/cards/CardsLayout.vue";
import MenuCard from "../components/cards/MenuCard.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import {awaitable} from "../components/Awaitable";
import {fetch} from "../API";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

const {data: projects, mutate} = useSWR("/project/", {}, {});

const router = useRouter();
const adding = ref(false);
const addProject = awaitable(adding, async () =>
{
    const id = await fetch("post", "/project/", {}, {}, null);
    await router.push(`/project/${id}/define/`);
});

const remove = ref<(() => Promise<void>) | null>(null);
const removing = ref(new Set<string>());
const removeProject = async (projectId: string) =>
{
    remove.value = async () =>
    {
        remove.value = null;
        removing.value.add(projectId);
        try
        {
            await fetch("delete", "/project/{projectId}/", {projectId}, {}, null);
            projects.value = projects.value!.filter(({id}) => id !== projectId);
            await mutate();
        }
        finally
        {
            removing.value.delete(projectId);
        }
    };
};
const goToProject = (projectId: string) => router.push(`/project/${projectId}/`);
</script>
<template>
    <h1>All projects</h1>
    <CardsLayout>
        <AddCard v-bind:loading="adding" v-on:click="addProject()">
            <template v-slot:icon>
                <div class="i-material-symbols:create-new-folder-outline-rounded"/>
            </template>
            <template v-slot:default>
                <div>Create a project to track sustainability or social impact</div>
            </template>
        </AddCard>
        <MenuCard v-bind:loading="true" v-if="projects === undefined"/>
        <TransitionGroup enterActiveClass="transition-transform" enterFromClass="transform-scale-0" enterToClass="transform-scale-100" leaveActiveClass="transition-transform" leaveFromClass="transform-scale-100" leaveToClass="transform-scale-0">
            <template v-bind:key="id" v-for="({id, name}) of projects ?? []">
                <div>
                    <MenuCard v-bind:loading="removing.has(id)" v-bind:menu="[{action: () => removeProject(id), text: 'Delete project'}]" v-if="id" v-on:click="goToProject(id)">
                        <template v-slot:default>
                            <div class="text-6">{{name}}</div>
                        </template>
                    </MenuCard>
                </div>
            </template>
        </TransitionGroup>
    </CardsLayout>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete this project?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover it.</template>
        <template v-slot:yes>Yes, delete it</template>
        <template v-slot:no>No, keep it</template>
    </ModalQuestion>
</template>