<script lang="ts">
import {type Card, type CardComponent, components} from "./ReportCards";
import {computed, toRaw} from "vue";
import type ChartCard from "./components/ChartCard.vue";
import {ReportMode} from "./ReportMode";
import {VueDraggableNext} from "vue-draggable-next";
import {useSWR} from "../SWR";

export default
{
    components
};

type AddonChart = CardComponent<"ChartCard", typeof ChartCard> & {name: string};
type AddonOther = Exclude<Card, CardComponent<"ChartCard", typeof ChartCard>> & {title: string};
type Addon = AddonChart | AddonOther;

const ADDONS: Addon[] =
[
    {card: "TitleCard", title: "Section title", value: ""},
    {card: "ParagraphCard", title: "Paragraph", value: ""},
    {card: "QuoteCard", title: "Quote", value: ""},
    {card: "StatisticsCard", title: "Statistics", value: [null, null, null]},
    {card: "ImageCard", title: "Images", value: [null, null]},
    {card: "VideoCard", title: "Videos", value: [null, null]}
];
</script>
<script lang="ts" setup>
interface Props
{
    id?: string;
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});
const clone = (original: any) => structuredClone(toRaw(original));

const {data: indicators} = useSWR("/project/{projectId}/analyze/", {projectId: props.projectId}, {});
const addons = computed<Addon[]>(() =>
{
    const {projectId} = props;
    const charts: AddonChart[] = indicators.value?.map<AddonChart>(({indicatorId, name, parameterId}) => ({card: "ChartCard", name, props: {value: {indicatorId, parameterId: parameterId ?? "", projectId}}, value: {indicatorId, parameterId: parameterId ?? "", projectId}})) ?? [];
    return [...ADDONS, ...charts];
});

const addonChart = (addon: Addon): addon is AddonChart => (addon as AddonChart).name !== undefined;
const addonOther = (addon: Addon): addon is AddonOther => (addon as AddonOther).title !== undefined;
</script>
<style scoped>
.ghost-toolbar
{
    opacity: 0.5;
}
</style>
<template>
    <div class="flex flex-col flex-gap-6">
        <div class="flex flex-col flex-gap-3" v-bind:key="index" v-for="(item, index) in addons">
            <div v-if="addonChart(item)">
                <div class="text-small">{{item.name}}</div>
            </div>
            <div class="font-600" v-else-if="addonOther(item)">{{item.title}}</div>
            <VueDraggableNext fallback-class="dragging-toolbar" ghost-class="ghost-toolbar" v-bind:clone="clone" v-bind:force-fallback="true" v-bind:group="{name: id, pull: 'clone', put: false}" v-bind:list="[item]" v-bind:sort="true">
                <div class="cursor-grab" v-bind:aria-label="addonChart(item) ? `${item.name}` : addonOther(item) ? item.title : ''">
                    <Component v-bind:is="item.card" v-bind="item.props" v-bind:mode="ReportMode.Preview">
                        <template v-if="item.slot" v-slot:[item.slot.name]>{{item.slot.value}}</template>
                    </Component>
                </div>
            </VueDraggableNext>
        </div>
    </div>
</template>
