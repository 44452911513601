<script lang="ts" setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watchPostEffect} from "vue";

interface Emits
{
    (event: "update:values", values: string[]): void;
}
interface Props
{
    disabled?: boolean;
    readonly?: boolean;
    tags?: string[];
    values?: string[];
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    disabled: false,
    readonly: false,
    tags: () => [],
    values: () => []
});
const add = (item: string) =>
{
    const {values} = props;
    if(values.includes(item))
    {
        remove(item);
    }
    else
    {
        emit("update:values", [...values, item]);
    }
    text.value = "";
};
const remove = (item: string) =>
{
    const values = props.values.filter((v) => v !== item);
    emit("update:values", values);
};

const el = ref<HTMLDivElement>();
const popup = ref(false);
const dropdown = () =>
{
    if(props.disabled === false)
    {
        popup.value = !popup.value;
        el.value!.focus();
        nextTick(() => scrollIntoView());
    }
};

const id = `id_${Math.random().toString(36).substring(2)}`;
const dismiss = (event: MouseEvent) =>
{
    if(event.target)
    {
        const target = event.target as HTMLElement;
        if(target.closest(`[data-id=${id}]`) === null)
        {
            popup.value = false;
            addText();
        }
    }
};
onMounted(() => window.addEventListener("click", dismiss));
onBeforeUnmount(() => window.removeEventListener("click", dismiss));

const list = ref<HTMLDivElement>();
const scrollIntoView = () => list.value!.querySelector("div[aria-selected='true']")?.scrollIntoView({behavior: "instant", block: "nearest"});
watchPostEffect(() => nextTick(() => scrollIntoView()));

const text = ref("");
const addText = () =>
{
    if(text.value.length > 0)
    {
        add(text.value);
        text.value = "";
    }
};
const removeLast = () =>
{
    if(text.value.length === 0)
    {
        const {values} = props;
        const {length} = values;
        if(length > 0)
        {
            const item = values[length - 1];
            remove(item);
        }
    }
};

const filter = () =>
{
    if(text.value.length === 0)
    {
        popup.value = false;
    }
    else
    {
        popup.value = true;
    }
};
const filtered = computed(() =>
{
    const search = text.value.toLowerCase();
    const {tags} = props;
    return tags.filter((item) => item.toLowerCase().includes(search));
});
const highlight = (item: string) =>
{
    const search = text.value.toLowerCase();
    const index = item.toLowerCase().indexOf(search);
    return index === -1 ? item : [item.substring(0, index), item.substring(index, index + search.length), item.substring(index + search.length)];
};
</script>
<template>
    <div aria-multiselectable class="outline-none parent relative" ref="el" role="combobox" tabindex="0" v-bind:aria-disabled="disabled" v-bind:data-id="id" v-on:keydown.enter="dropdown" v-on:keydown.space="dropdown">
        <div class="b-b-1 b-b-solid min-h-10 flex flex-items-center flex-items-stretch flex-justify-between" v-bind:class="[disabled ? '' : 'cursor-pointer']">
            <div class="flex flex-grow flex-wrap">
                <div class="b-1 b-solid box-content cursor-default flex-items-center flex-justify-start rounded-2 inline-flex m-r-2 m-y-1 p-1 text-sm" v-bind:class="[disabled ? '' : '']" v-bind:key="index" v-for="(item, index) in values">
                    <span>{{item}}</span>
                    <span class="color-gray hover-color-black i-fa6-solid:circle-xmark m-l-1" v-bind:class="[disabled ? '' : 'cursor-pointer']" v-on:click.stop="remove(item)" v-on:mousedown.stop=""/>
                </div>
                <input class="b-none flex-grow font-sans min-w-20em outline-none self-stretch text-body p-y-2" v-model="text" v-on:input="filter" v-on:keydown.enter.prevent.stop="addText" v-on:keydown.stop.delete="removeLast()"/>
            </div>
            <div class="flex flex-items-center p-l-2" v-on:mousedown.prevent.stop="dropdown" v-if="readonly === false">
                <span class="triangle-0.4" v-bind:class="[disabled ? 'color-middlegray' : 'color-black']"/>
            </div>
        </div>
        <div class="absolute bg-white block b-1 b-color-verylightgray b-t-0 b-solid b-rd-b-2 box-border font-sans max-h-40 overflow-y-auto shadow transform-origin-t transition-transform w-100% z-1" ref="list" v-bind:class="popup ? 'transform-scale-y-100' : 'transform-scale-y-0'" v-on:transitionstart="scrollIntoView">
            <div class="block box-border color-black cursor-pointer flex flex-items-center font-sans h-10 p-2" role="option" v-bind:aria-selected="values.includes(item)" v-bind:class="values.includes(item) ? 'bg-gray-2' : 'bg-white'" v-bind:key="index" v-bind:value="index" v-for="(item, index) in filtered" v-on:mousedown="add(item)" v-on:mouseup="popup = false">
                <template v-for="(text, index) of highlight(item)">
                    <span class="underline white-space-pre" v-if="index === 1">{{text}}</span>
                    <span class="white-space-pre" v-else>{{text}}</span>
                </template>
            </div>
        </div>
    </div>
</template>