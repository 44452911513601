<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {DateTime} from "luxon";
import Loader from "./components/Loader.vue";
import QRCode from "qrcode.vue";
import {fetch} from "./API";
import {useSWR} from "./SWR";

const {VITE_TELEGRAM_BOT_NAME} = import.meta.env;

const {data, mutate} = useSWR("/signin/telegram/", {}, {});
const timer = ref<number>();

watch(data, (data) =>
{
    if(data !== undefined)
    {
        const {expires} = data;
        const timeout = DateTime.fromISO(expires).diffNow().minus(30000).toMillis();
        const refetch = async () =>
        {
            const updated = await fetch("get", "/signin/telegram/", {}, {}, null);
            await mutate(updated);
        };
        timer.value = window.setTimeout(refetch , timeout);
    }
});
onUnmounted(() => window.clearTimeout(timer.value));

const href = computed(() => data.value === undefined ? undefined : `https://t.me/${VITE_TELEGRAM_BOT_NAME}?start=${data.value.token}`);
const open = (e: Event) =>
{
    window.open(href.value, "_blank");
    e.preventDefault();
};

const face = ref(0);
const interval = ref<number>();
onMounted(() => interval.value = window.setInterval(() => face.value = Math.floor(Math.random() * 3), 4000));
onUnmounted(() => window.clearInterval(interval.value));
</script>
<template>
    <Loader v-bind:loading="href === undefined">
        <div class="flex flex-justify-center m-t-4">
            <div class="flex flex-col flex-gap-3 flex-items-center w-60">
                <div class="grid grid-cols-1 grid-rows-1 w-100%">
                    <QRCode class="grid-col-[1] grid-row-[1] " level="M" render-as="svg" v-bind:height="undefined" v-bind:value="href" v-bind:width="undefined"/>
                    <div class="b-rd-100% bg-white grid-col-[1] grid-row-[1] p-1 h-20% justify-self-center self-center w-20%">
                        <div class="bg-blue i-ic:baseline-telegram h-100% w-100%"/>
                    </div>
                </div>
                <a class="link" v-bind:href="href" v-on:click="open">Open in Telegram</a>
                <div class="grid grid-gap-3 grid-cols-[1fr]">
                    <div class="text-center">Easily enter indicator data or take a photo of an utility bill for the Cheeri Telegram bot to update the data for you.</div>
                </div>
                <div class="bg-black h-12 w-12" v-bind:class="['i-mage:robot', 'i-mage:robot-appreciate', 'i-mage:robot-happy'][face]"/>
            </div>
        </div>
    </Loader>
</template>
