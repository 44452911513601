<script lang="ts" setup>
import {Frequencies, Types} from "./Indicator.vue";
import {type IndicatorSuggestion, fetch} from "../API";
import Toolbar, {type ToolbarButton} from "../components/Toolbar.vue";
import {awaitable, awaitableSelection} from "../components/Awaitable";
import {computed, ref} from "vue";
import {useBooleanPreference, useJsonPreference} from "../components/Preference";
import Accordion from "../components/Accordion.vue";
import Action from "../components/Action.vue";
import Base64 from "../components/Base64";
import Button from "../components/Button.vue";
import Checkbox from "../components/Checkbox.vue";
import Field from "../components/Field.vue";
import Modal from "../components/Modal.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import Table from "../components/Table.vue";
import Textbox from "../components/Textbox.vue";
import Upload from "../components/Upload.vue";
import {authorization} from "../Authorization";
import {encode} from "gpt-tokenizer/esm/model/gpt-4o-mini";
import {extractText} from "../components/PDF";
import {invokable} from "../components/Invokable";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: indicators, mutate} = useSWR("/project/{projectId}/indicator/", {projectId: props.projectId}, {});
const router = useRouter();

const addIndicator = async () =>
{
    const {projectId} = props;
    const indicatorId = await fetch("post", "/project/{projectId}/indicator/", {projectId}, {}, null);
    await router.push(`/project/${props.projectId}/indicator/${indicatorId}/`);
};

const adding = ref(false);
const {data: templates} = useSWR("/template/", {}, {fields: ["name", "tags"]});
const addingFromTemplate = ref(false);
const addFromTemplate = () =>
{
    addingFromTemplate.value = true;
};
const filter = ref("");
const filtered = computed(() =>
{
    if(templates.value === undefined)
    {
        return undefined;
    }
    else
    {
        const select = filter.value.toLowerCase().split(/\s+/g);
        return templates.value.filter(({name, tags}) => select.every((s) => name!.toLowerCase().includes(s) || tags!.some((tag) => tag.toLowerCase().includes(s))));
    }
});
const selectTemplate = awaitable(adding, async (id: string, name: string) =>
{
    addingFromTemplate.value = false;
    const {projectId} = props;
    const [templateId] = await fetch("post", "/project/{projectId}/indicator/template/clone/", {projectId}, {}, [{id, name}]);
    await router.push(`/project/${projectId}/indicator/${templateId}/`);
});

const gotoEdit = async (indicatorId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId}/indicator/${indicatorId}/`);
};
const gotoGHG = async (indicatorId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId}/assistant/`);
};
const gotoMeasure = async (indicatorId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId!}/indicator/${indicatorId}/measurement/`);
};

const selected = ref<string[]>([]);
const select = (checked: boolean, indicatorId: string) =>
{
    if(checked)
    {
        selected.value.push(indicatorId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== indicatorId);
    }
};

const removing = ref(new Set<string>());
const remove = ref<(() => Promise<void>) | null>(null);
const removeIndicators = invokable(remove, awaitableSelection(removing, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    const promises = indicatorIds.map((indicatorId) => fetch("delete", "/project/{projectId}/indicator/{indicatorId}/", {indicatorId, projectId}, {}, null));
    await Promise.all(promises);
    indicators.value = indicators.value!.filter(({id}) => indicatorIds.includes(id) === false);
    selected.value = selected.value.filter((id) => indicatorIds.includes(id) === false);
    await mutate();
}));

const cloning = ref(new Set<string>());
const cloneIndicators = awaitableSelection(cloning, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    await fetch("post", "/project/{projectId}/indicator/clone/", {projectId}, {}, indicatorIds);
    indicators.value = await fetch("get", "/project/{projectId}/indicator/", {projectId}, {}, null);
    await mutate(indicators.value);
});

const creating = ref(new Set<string>());
const createTemplate = awaitableSelection(creating, async (indicatorIds: string[]) =>
{
    const {projectId} = props;
    await fetch("post", "/project/{projectId}/indicator/template/create/", {projectId}, {}, indicatorIds);
});

const toolbar = computed(() =>
{
    const administrator = authorization.value?.roles.includes("administrator");
    const createTemplateButton: ToolbarButton =
    {
        action: () => createTemplate([...selected.value]),
        disabled: selected.value.length === 0,
        icon: "i-tabler:copy-plus-filled" as const,
        label: "Save as templates",
        wait: creating.value.size > 0
    };
    const optional = administrator ? [createTemplateButton] : [];
    const buttons: ToolbarButton[] =
    [
        {
            action: addIndicator,
            icon: "i-material-symbols:add",
            label: "Add indicator"
        },
        {
            action: addFromTemplate,
            icon: "i-tabler:copy-check-filled",
            label: "Add from template",
            wait: adding.value
        },
        {
            action: () => cloneIndicators([...selected.value]),
            disabled: selected.value.length === 0,
            icon: "i-tabler:copy-plus-filled",
            label: "Clone indicators",
            wait: cloning.value.size > 0
        },
        ...optional,
        {
            action: () => removeIndicators([...selected.value]),
            disabled: selected.value.length === 0,
            icon: "i-material-symbols:delete-outline-rounded",
            label: "Remove indicators",
            wait: removing.value.size > 0
        }
    ];
    return buttons;
});

const suggestions = useJsonPreference<{filename: string; indicators: IndicatorSuggestion[]} | null>(computed(() => `suggestion:${props.projectId}`), 1, null);

function *split(maximum: number, text: string)
{
    const tokens = encode(text);
    let n: number;
    for(n = 0; n < tokens.length; n += maximum)
    {
        const chunk = tokens.slice(n, n + maximum);
        yield chunk;
    }
    if(n < tokens.length)
    {
        yield tokens.slice(n);
    }
}

const filename = ref<string | null>(suggestions.value === null ? null : suggestions.value.filename);
const status = ref<"finished" | "idle" | "no-content" | "not-pdf">(filename.value === null ? "idle" : "finished");
const working = ref(false);
const upload = awaitable(working, async (buffer: ArrayBuffer, name: string, type: string) =>
{
    filename.value = name;
    if(type === "application/pdf")
    {
        const pages = await extractText(buffer);
        const text = pages.join("\n");
        if(text.length === 0)
        {
            status.value = "no-content";
        }
        else
        {
            for(const chunk of split(14000, text))
            {
                const {projectId} = props;
                const {indicators} = await fetch("post", "/project/{projectId}/indicator/extract/", {projectId: projectId!}, {}, chunk);
                suggestions.value = {filename: name, indicators};
                status.value = "finished";
                break;
            }
        }
    }
    else
    {
        status.value = "not-pdf";
    }
});

const expanded = useBooleanPreference("pdf", true);

const accepting = ref<Set<number>>(new Set());
const acceptIndicatorSuggestion = awaitableSelection(accepting, async (index: number) =>
{
    const {projectId} = props;
    const suggestion = suggestions.value!.indicators.at(index);
    const indicatorId = await fetch("post", "/project/{projectId}/indicator/", {projectId: projectId!}, {}, null);
    await router.push(`/project/${projectId}/indicator/${indicatorId}/?suggestion=${Base64.encode(JSON.stringify(suggestion))}`);
});

const discardSuggestions = () =>
{
    suggestions.value = null;
    filename.value = null;
    status.value = "idle";
};

useBreadcrumb();
</script>
<template>
    <h1>Measure indicators</h1>
    <p>Manage and enter measurements for self-defined and standardized indicators.</p>
    <Accordion v-if="false" v-model:expanded="expanded">
        <template v-slot:header>Extract from PDF</template>
        <template v-slot:default>
            <div class="flex flex-gap-3 flex-justify-between">
                <div v-if="status === 'idle'">Upload an existing grant application, grant report or impact report in <b>PDF format</b>. Once selected, we’ll automatically process the file and extract draft indicators.</div>
                <div class="text-pink" v-else-if="status === 'no-content'">The file "{{filename}}" you uploaded does not contain any text.</div>
                <div class="text-pink" v-else-if="status === 'not-pdf'">The file "{{filename}}" you uploaded is not a PDF. Please upload a PDF to extract draft indicators.</div>
                <div v-else-if="status === 'finished'">Based on "{{filename}}", the following draft indicators have been extracted. Upload another document for a different draft.</div>
                <div>
                    <Upload accept=".pdf" v-on:upload="upload">
                        <template v-slot:default="{select}">
                            <Button fitted role="secondary" type="button" v-bind:loading="working" v-on:click="select">Upload</Button>
                        </template>
                    </Upload>
                </div>
            </div>
        </template>
    </Accordion>
    <Accordion v-model:expanded="expanded">
        <template v-slot:header>Reporting GHG Scope 1 and Scope 2?</template>
        <template v-slot:default>
            <div class="flex flex-gap-3 flex-justify-between">
                <div>Want to report GHG Scope 1 and Scope 2 but don’t know how to get started? Start this assistant to add GHG indicators that are relevant for your organization.</div>
                <div>
                    <Button fitted role="secondary" type="button" v-on:click="gotoGHG">Start</Button>
                </div>
            </div>
        </template>
    </Accordion>
    <div v-if="suggestions">
        <div class="flex flex-items-center">
            <h4 class="flex-grow">Suggested indicators</h4>
            <a aria-label="Edit" class="flex flex-items-center link text-body" v-on:click="discardSuggestions">
                <span class="i-material-symbols:delete-outline-rounded m-r-1"/>
                Discard suggestions
            </a>
        </div>
        <Table class="m-t-3" collapse-first collapse-last v-bind:columns="5" v-bind:rows="suggestions.indicators">
            <template v-slot:header:1>Name</template>
            <template v-slot:header:2>Type</template>
            <template v-slot:header:3>Frequency</template>
            <template v-slot:header:4>Description</template>
            <template v-slot:header:5/>
            <template v-slot:column:1="{row: {name}}">
                <span class="font-bold">{{name}}</span>
            </template>
            <template v-slot:column:2="{row: {type}}">
                <span>{{type === undefined ? "-" : typeSafeObjectFromEntries(Types)[type]}}</span>
            </template>
            <template v-slot:column:3="{row: {frequency}}">
                <span>{{frequency === undefined ? "-" : typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
            </template>
            <template v-slot:column:4="{row: {description}}">
                <div class="max-w-8em overflow-hidden text-ellipsis white-space-nowrap">{{description ?? "-"}}</div>
            </template>
            <template v-slot:column:5="{index}">
                <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="accepting.has(index)"/>
                <a aria-label="Edit" class="i-material-symbols:add link text-6" v-on:click="acceptIndicatorSuggestion(index)" v-else/>
            </template>
        </Table>
    </div>
    <Toolbar class="m-t-6" v-bind:buttons="toolbar"/>
    <Table class="m-t-3" collapse-first collapse-last v-bind:columns="5" v-bind:rows="indicators">
        <template v-slot:header:1>Indicator</template>
        <template v-slot:header:2>Type</template>
        <template v-slot:header:3>Frequency</template>
        <template v-slot:header:4>Measurements</template>
        <template v-slot:column:1="{row: {id, name}}">
            <Checkbox v-bind:disabled="cloning.has(id) || removing.has(id)" v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)">
                <span class="font-bold">{{name}}</span>
            </Checkbox>
        </template>
        <template v-slot:column:2="{row: {type}}">
            <span>{{typeSafeObjectFromEntries(Types)[type]}}</span>
        </template>
        <template v-slot:column:3="{row: {frequency}}">
            <span>{{typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
        </template>
        <template v-slot:column:4="{row: {measurements}}">
            <div class="max-w-8em overflow-hidden text-ellipsis white-space-nowrap">{{measurements}} entries</div>
        </template>
        <template v-slot:column:5="{row: {id}}">
            <div class="flex flex-gap-2 flex-justify-center flex-items-center flex-grow flex-row">
                <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="removing.has(id)"/>
                <template v-else>
                    <Action icon="i-material-symbols:edit-outline-rounded" label="Edit indicator" v-on:action="gotoEdit(id)"/>
                    <Action icon="i-mdi:database-edit-outline" label="Edit measurements" v-on:action="gotoMeasure(id)" v-if="projectId"/>
                </template>
            </div>
        </template>
        <template v-slot:placeholder v-if="indicators?.length === 0">
            <div class="flex flex-justify-center">
                <div class="color-lightgray">No indicators created yet</div>
            </div>
        </template>
    </Table>
    <Modal class="h-[min-content] min-h-[100vh] w-[min(100vw,60em)]" closable v-model:visible="addingFromTemplate">
        <h2>Select indicator template</h2>
        <form v-on:submit.prevent="">
            <Field label="Search">
                <Textbox autofocus v-model:value="filter"/>
            </Field>
        </form>
        <Table selectable v-bind:columns="2" v-bind:rows="filtered" v-on:select="({id, name}) => selectTemplate(id, name!)">
            <template v-slot:header:1>Name</template>
            <template v-slot:header:2>Tags</template>
            <template v-slot:column:1="{row: {name}}">{{name}}</template>
            <template v-slot:column:2="{row: {tags}}">
                <div class="flex flex-gap-1 flex-wrap">
                    <div class="bg-verylightgray b-1 b-rd-[1000vw] b-solid b-lightgray grid grid-cols-[max-content_max-content] grid-gap-1 not-last-m-r-1 p-x-2" v-bind:key="index" v-for="(tag, index) of tags">
                        <div class="text-middlegray">{{tag.split(":", 2)[0]}}:</div>
                        <div class="text-gray">{{tag.split(":", 2)[1]}}</div>
                    </div>
                </div>
            </template>
            <template v-slot:placeholder v-if="filtered?.length === 0">
                <div class="flex flex-justify-center">
                    <div class="color-lightgray">No template found.</div>
                </div>
            </template>
        </Table>
    </Modal>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete {{selected.length}} {{selected.length === 1 ? "indicator" : "indicators"}}?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover {{selected.length === 1 ? "it" : "them"}}.</template>
        <template v-slot:yes>Yes, delete {{selected.length === 1 ? "it" : "them"}}</template>
        <template v-slot:no>No, keep {{selected.length === 1 ? "it" : "them"}}</template>
    </ModalQuestion>
</template>