<script lang="ts" setup>
import {Frequencies, Types} from "../indicator/Indicator.vue";
import {computed, ref, watchEffect} from "vue";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import Checkbox from "../components/Checkbox.vue";
import Field from "../components/Field.vue";
import Table from "../components/Table.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    surveyId: string;
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: survey, mutate} = useSWR("/project/{projectId}/survey/{surveyId}/", {projectId: props.projectId, surveyId: props.surveyId}, {});
const {data: indicators} = useSWR("/project/{projectId}/indicator/", {projectId: props.projectId}, {});

watchEffect(() =>
{
    if(survey.value === null)
    {
        survey.value = {};
    }
});

const getName = () => survey.value?.name ?? "";
const setName = (value: string) =>
{
    survey.value!.name = value;
    autosave();
};
const name = computed({get: getName, set: setName});

const getSelected = () => survey.value?.indicators ?? [];
const setSelected = (value: string[]) =>
{
    survey.value!.indicators = value;
    autosave();
};
const selected = computed({get: getSelected, set: setSelected});

const entity = computed(() => ({indicators: selected.value, name: survey.value!.name!}));

const validation = ref();
const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/survey/{surveyId}/", async (fetch) =>
{
    if(validation.value!.validate())
    {
        const {projectId, surveyId} = props;
        await fetch({projectId, surveyId}, {}, entity.value);
        await mutate(entity.value);
    }
});

const router = useRouter();
const submit = async () =>
{
    if(validation.value!.validate(true))
    {
        const {projectId, surveyId} = props;
        await save();
        await router.push(`/project/${projectId}/survey/${surveyId}/share/`);
    }
};
const disabled = computed(() => survey.value === undefined || survey.value === null);

const select = (checked: boolean, indicatorId: string) =>
{
    if(checked)
    {
        selected.value = [...selected.value, indicatorId];
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== indicatorId);
    }
};

useBreadcrumb({surveyId: () => survey.value?.name});
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Define survey</h1>
            <Validation ref="validation">
                <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit">
                    <Field label="Survey name" rule="NonEmptyString" v-bind:value="name" v-slot="{id}">
                        <Textbox v-bind:id="id" v-model:value="name"/>
                    </Field>
                    <Table class="m-t-3" collapse-first v-bind:columns="4" v-bind:rows="indicators">
                        <template v-slot:header:1>Indicator</template>
                        <template v-slot:header:2>Type</template>
                        <template v-slot:header:3>Frequency</template>
                        <template v-slot:header:4>Measurements</template>
                        <template v-slot:column:1="{row: {id, name}}">
                            <Checkbox v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)">
                                <span class="font-bold">{{name}}</span>
                            </Checkbox>
                        </template>
                        <template v-slot:column:2="{row: {type}}">
                            <span>{{typeSafeObjectFromEntries(Types)[type]}}</span>
                        </template>
                        <template v-slot:column:3="{row: {frequency}}">
                            <span>{{typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
                        </template>
                        <template v-slot:column:4="{row: {measurements}}">
                            <div class="max-w-8em overflow-hidden text-ellipsis white-space-nowrap">{{measurements}} entries</div>
                        </template>
                        <template v-slot:placeholder v-if="indicators?.length === 0">
                            <div class="flex flex-justify-center">
                                <div class="color-lightgray">No indicators created yet</div>
                            </div>
                        </template>
                    </Table>
                </form>
            </Validation>
        </template>
        <template v-slot:buttons>
            <Button role="primary" type="submit" v-bind:disabled="disabled" v-bind:loading="saving" v-on:click="submit">Continue</Button>
        </template>
    </ButtonContainer>
</template>