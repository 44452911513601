<script lang="ts" setup>
import {computed, ref} from "vue";
import Chart from "./Chart.vue";
import Dropdown from "../components/Dropdown.vue";
import Loader from "../components/Loader.vue";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const filter = ref("all");
const query = computed<{aggregate?: "all" | "internal" | "external", respondentId?: string}>(() => filter.value === null ? {aggregate: "all" as const} : filter.value === "all" || filter.value === "external" || filter.value === "internal" ? {aggregate: filter.value} : {respondentId: filter.value});
const {data: indicators} = useSWR("/project/{projectId}/analyze/", {projectId: props.projectId}, query);
const {data: respondents} = useSWR("/project/{projectId}/analyze/respondent/", {projectId: props.projectId}, {});

const filters = computed<Array<{aggregate?: "all" | "internal" | "external", id?: string, name: string}>>(() =>
{
    const aggregate = [{aggregate: "all" as const, name: "All data"}, {aggregate: "internal" as const, name: "Internal data only"}, {aggregate: "external" as const, name: "External data only"}];
    if(respondents.value === undefined)
    {
        return aggregate;
    }
    else
    {
        return [...aggregate, ...respondents.value];
    }
});

useBreadcrumb();
</script>
<template>
    <div class="flex flex-col justify-between sm-flex-gap-4 sm-flex-row">
        <h1>Analyze impact</h1>
        <Dropdown class="sm-w-auto w-100%" variant="minimal" v-bind:disabled="respondents === undefined" v-bind:items="filters.map(({aggregate, id, name}) => [aggregate ?? id, name])" v-model:selected="filter"/>
    </div>
    <Loader v-bind:loading="indicators === undefined">
        <template v-if="indicators!.length === 0">
            <p>You have not added any indicator to measure and analyze impact yet. Go to <router-link class="link" v-bind:to="`/project/${projectId}/indicators/`">Add indicator</router-link> to define what impact to analyze.</p>
        </template>
        <template v-else>
            <div v-bind:key="index" v-for="({currency, name, options, parameter, unit, values}, index) of indicators">
                <h2>{{name}}</h2>
                <h4 v-if="parameter">{{parameter}}</h4>
                <Chart v-bind:indicator="{currency, name, options, unit}" v-bind:measurements="values"/>
            </div>
        </template>
    </Loader>
</template>
