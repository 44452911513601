<script lang="ts">
import type {IndicatorFormula, IndicatorFrequency, IndicatorType, PathRequestBody, Unit} from "../API";
import {computed, onMounted, ref, watchEffect} from "vue";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import AsyncValidation from "../components/AsyncValidation.vue";
import Base64 from "../components/Base64";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Currencies from "./Currencies.json";
import Dropdown from "../components/Dropdown.vue";
import Field from "../components/Field.vue";
import Formula from "./components/Formula.vue";
import IndicatorUnit from "./components/IndicatorUnit.vue";
import MultipleDropdown from "../components/MultipleDropdown.vue";
import Multitext from "../components/Multitext.vue";
import SDGs from "./SDGs.json";
import TagsDropdown from "../components/TagsDropdown.vue";
import Textarea from "../components/Textarea.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

export const Frequencies: [IndicatorFrequency, string][] =
[
    ["P0", "Once only"],
    ["P1D", "Every day"],
    ["P1W", "Every week"],
    ["P1M", "Every month"],
    ["P3M", "Four times a year"],
    ["P1Y", "Every year"]
];

export const Types: [IndicatorType, string][] =
[
    ["text", "Open-ended text"],
    ["number", "Number"],
    ["single-choice", "Choose exactly one option"],
    ["multiple-choice", "Choose one or more options"],
    ["monetary-value", "Monetary value"],
    ["unit", "Standardized unit"],
    ["formula", "Calculated value"]
];
</script>
<script lang="ts" setup>
interface Props
{
    indicatorId: string;
    projectId: string;
    suggestion?: string | null;
}
const props = withDefaults(defineProps<Props>(), {});

interface Indicator
{
    currency?: string;
    description?: string;
    formula?: IndicatorFormula;
    frequency?: IndicatorFrequency;
    measurements?: number;
    name?: string;
    options?: string[];
    sdgs?: number[];
    tags?: string[];
    type?: IndicatorType;
    unit?: Unit;
}
const {data: indicator, mutate} = useSWR("/project/{projectId}/indicator/{indicatorId}/", {indicatorId: props.indicatorId, projectId: props.projectId}, {});
const {data: prefedined} = useSWR("/template/tags/", {projectId: props.projectId}, {});
onMounted(async () =>
{
    const {suggestion} = props;
    if(suggestion !== null && suggestion !== undefined)
    {
        const json = Base64.decode(suggestion);
        const prefil = JSON.parse(json) as Indicator;
        indicator.value = prefil;
    }
});
watchEffect(() =>
{
    if(indicator.value === null)
    {
        indicator.value = {};
    }
});

const entity = computed((): PathRequestBody<"/project/{projectId}/indicator/{indicatorId}/", "patch"> =>
{
    const {currency, description, formula, frequency, name, options, sdgs, tags, type, unit} = indicator.value!;
    switch(type)
    {
        case "formula":
        {
            return {description: description!, formula: formula!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "number":
        case "text":
        {
            return {description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "multiple-choice":
        case "single-choice":
        {
            return {description: description!, frequency: frequency!, name: name!, options: options!, sdgs, tags: tags!, type};
        }
        case "monetary-value":
        {
            return {currency: currency!, description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type};
        }
        case "unit":
        {
            return {description: description!, frequency: frequency!, name: name!, sdgs, tags: tags!, type, unit: unit!};
        }
        default:
        {
            return null as never;
        }
    }
});

const getName = () => indicator.value?.name ?? "";
const setName = (value: string) =>
{
    indicator.value!.name = value;
    autosave();
};
const name = computed({get: getName, set: setName});

const getDescription = () => indicator.value?.description ?? "";
const setDescription = (value: string) =>
{
    indicator.value!.description = value;
    autosave();
};
const description = computed({get: getDescription, set: setDescription});

const getFrequency = () => indicator.value?.frequency ?? null;
const setFrequency = (value: IndicatorFrequency | null) =>
{
    indicator.value!.frequency = value ?? undefined;
    autosave();
};
const frequency = computed({get: getFrequency, set: setFrequency});

const getSDGs = () => indicator.value?.sdgs?.map((v) => v - 1) ?? [];
const setSDGs = (value: number[]) =>
{
    indicator.value!.sdgs = value.map((v) => v + 1);
    autosave();
};
const sdgs = computed({get: getSDGs, set: setSDGs});

const getTags = () => indicator.value?.tags ?? [];
const setTags = (value: string[]) =>
{
    indicator.value!.tags = value;
    autosave();
};
const tags = computed({get: getTags, set: setTags});

const getType = () => indicator.value?.type ?? null;
const setType = (value: IndicatorType | null) =>
{
    indicator.value!.type = value ?? undefined;
    autosave();
};
const type = computed({get: getType, set: setType});

const getCurrency = () => indicator.value?.currency ?? null;
const setCurrency = (value: string | null) =>
{
    indicator.value!.currency = value ?? undefined;
    autosave();
};
const currency = computed({get: getCurrency, set: setCurrency});

const getOptions = () => indicator.value?.options ?? ["", ""];
const setOptions = (value: string[]) =>
{
    indicator.value!.options = value;
    autosave();
};
const options = computed({get: getOptions, set: setOptions});

const getUnit = () => indicator.value?.unit ?? null;
const setUnit = (unit: Unit | null) =>
{
    indicator.value!.unit = unit === null ? undefined : unit;
    autosave();
};
const unit = computed({get: getUnit, set: setUnit});

const getFormula = () => indicator.value?.formula ?? {expression: "", parameters: {}, unit: ""};
const setFormula = async (value: IndicatorFormula) =>
{
    indicator.value!.formula = value;
    autosave();
};

const formula = computed<IndicatorFormula>({get: getFormula, set: setFormula});

const asyncValidation = ref<ComponentExposed<typeof AsyncValidation>>();
const validation = ref<ComponentExposed<typeof Validation>>();

const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/indicator/{indicatorId}/", async (fetch) =>
{
    if(validation.value!.validate() && await asyncValidation.value!.validate())
    {
        const {indicatorId, projectId} = props;
        await fetch({indicatorId, projectId}, {}, entity.value);
        await mutate(entity.value);
    }
});

const router = useRouter();
const submit = async () =>
{
    if(validation.value!.validate(true) && await asyncValidation.value!.validate(true))
    {
        await save();
        await router.push(`/project/${props.projectId}/indicator/`);
    }
};
const readonly = computed(() => indicator.value === undefined || indicator.value === null ? false : indicator.value.measurements === undefined ? false : indicator.value.measurements > 0);
const disabled = computed(() => indicator.value === undefined || indicator.value === null);

onBeforeRouteLeave(async () => await save());
useBreadcrumb({indicatorId: () => indicator.value?.name});
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Define custom indicator</h1>
            <p v-if="readonly">Note that only the indicator name, description and SDGs can be changed because measurements for this indicator already exist.</p>
            <AsyncValidation ref="asyncValidation">
                <Validation ref="validation">
                    <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit">
                        <Field label="Indicator name" rule="NonEmptyString" v-bind:value="name" v-slot="{id}">
                            <Textbox v-bind:disabled="disabled" v-bind:id="id" v-model:value="name"/>
                        </Field>
                        <Field label="Indicator description" rule="NonEmptyString" v-bind:value="description" v-slot="{id}">
                            <Textarea class="b-b b-b-color-inherit b-b-solid color-inherit p-y-2.5" v-bind:disabled="disabled" v-bind:id="id" v-model:value="description"/>
                        </Field>
                        <Field label="Tags" v-bind:value="tags" v-slot="{id}">
                            <TagsDropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:tags="prefedined" v-model:values="tags"/>
                        </Field>
                        <Field label="Related SDGs" v-bind:value="sdgs" v-slot="{id}">
                            <MultipleDropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="SDGs.map((v, n) => `SDG ${n + 1}: ${v}`)" v-model:selected="sdgs"/>
                        </Field>
                        <Field label="Frequency of measurement" rule="NotNull" v-bind:value="frequency" v-slot="{id}">
                            <Dropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="Frequencies" v-bind:readonly="readonly" v-model:selected="frequency"/>
                        </Field>
                        <Field label="Measurement type" rule="NotNull" v-bind:value="type" v-slot="{id}">
                            <Dropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="Types" v-bind:readonly="readonly" v-model:selected="type"/>
                        </Field>
                        <Field label="Options" rule="TwoOrMoreStrings" v-bind:value="options" v-if="type === 'multiple-choice' || type === 'single-choice'" v-slot="{id}">
                            <Multitext rule="NonEmptyString" v-bind:disabled="disabled" v-bind:id="id" v-bind:maximum="readonly ? options.length : 50" v-bind:minimum="readonly ? options.length : 2" v-bind:readonly="readonly" v-model:values="options"/>
                        </Field>
                        <Field label="Currency" rule="NotNull" v-bind:value="currency" v-if="type === 'monetary-value'" v-slot="{id}">
                            <Dropdown v-bind:disabled="disabled" v-bind:id="id" v-bind:items="Currencies.map((v) => [v, v])" v-bind:readonly="readonly" v-model:selected="currency"/>
                        </Field>
                        <IndicatorUnit v-bind:disabled="disabled" v-bind:optional="false" v-bind:readonly="readonly" v-model:unit="unit" v-if="type === 'unit'"/>
                        <Formula v-bind:disabled="disabled" v-bind:readonly="readonly" v-model:formula="formula" v-if="type === 'formula'"/>
                    </form>
                </Validation>
            </AsyncValidation>
        </template>
        <template v-slot:buttons>
            <Button role="primary" type="submit" v-bind:disabled="disabled" v-bind:loading="saving" v-on:click="submit">Save</Button>
        </template>
    </ButtonContainer>
</template>