<script lang="ts" setup>
import {computed, ref} from "vue";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Field from "../components/Field.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: project, mutate} = useSWR("/project/{projectId}/", {projectId: props.projectId}, {fields: ["name"]});

const getName = () => project.value === undefined || project.value === null ? "" : project.value.name;
const setName = (value: string) =>
{
    project.value!.name = value;
    autosave();
};
const name = computed({get: getName, set: setName});

const validation = ref<ComponentExposed<typeof Validation>>();

const {autosave, save, saving} = useAutosave("patch", "/project/{projectId}/", async (fetch) =>
{
    if(validation.value!.validate())
    {
        const {projectId} = props;
        await fetch({projectId}, {}, project.value!);
        await mutate();
    }
});
const router = useRouter();
const submit = async () =>
{
    if(validation.value!.validate(true))
    {
        await save();
        await router.push(`/project/${props.projectId}/`);
    }
};

const disabled = computed(() => project.value === undefined || project.value === null || saving.value);

onBeforeRouteLeave(async () => await save());
useBreadcrumb({projectId: () => project.value?.name});
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Define your project</h1>
            <Validation ref="validation">
                <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit()">
                    <Field label="Project name" rule="NonEmptyString" v-bind:value="name" v-slot="{id}">
                        <Textbox v-bind:disabled="disabled" v-bind:id="id" v-model:value="name"/>
                    </Field>
                </form>
            </Validation>
        </template>
        <template v-slot:buttons>
            <Button class="flex-self-center" role="primary" type="submit" v-bind:loading="saving" v-on:click="submit">Save</Button>
        </template>
    </ButtonContainer>
</template>