<script lang="ts">
import {Frequencies, Types} from "../indicator/Indicator.vue";
import Action from "../components/Action.vue";
import type {Navigation} from "../Navigation";
import type {RouteLocation} from "vue-router";
import Table from "../components/Table.vue";
import {typeSafeObjectFromEntries} from "../components/TypeSafe";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

export const navigation = (route: RouteLocation) =>
{
    const {params: {secret}} = route;
    const navigation: Array<Navigation & {description: string}> =
    [
        {
            description: "All indicators",
            href: `/y/${secret}`,
            icon: "i-mdi:target",
            text: "All indicators"
        }
    ];
    return navigation;
};
</script>
<script lang="ts" setup>
interface Props
{
    secret: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: indicators} = useSWR("/respondent/{secret}/", {secret: props.secret}, {});

const router = useRouter();
const gotoMeasure = (id: string) => router.push(`/y/${props.secret}/indicator/${id}/`);

useBreadcrumb();
</script>
<template>
    <h1>Indicators</h1>
    <p>Below are indicators to which other organizations have asked your organization to contribute sustainability data. Click on each indicator to enter your data.</p>
    <Table class="m-t-3" selectable v-bind:columns="6" v-bind:rows="indicators" v-on:select="({id}) => gotoMeasure(id)">
        <template v-slot:header:1>Name</template>
        <template v-slot:header:2>Project</template>
        <template v-slot:header:3>Type</template>
        <template v-slot:header:4>Frequency</template>
        <template v-slot:header:5>Measurements</template>
        <template v-slot:column:1="{row: {name}}">
            <span class="font-bold">{{name}}</span>
        </template>
        <template v-slot:column:2="{row: {project}}">
            <span>{{project}}</span>
        </template>
        <template v-slot:column:3="{row: {type}}">
            <span>{{type === undefined ? "-" : typeSafeObjectFromEntries(Types)[type]}}</span>
        </template>
        <template v-slot:column:4="{row: {frequency}}">
            <span>{{frequency === undefined ? "-" : typeSafeObjectFromEntries(Frequencies)[frequency]}}</span>
        </template>
        <template v-slot:column:5="{row: {measurements}}">
            <span>{{measurements}} entries</span>
        </template>
        <template v-slot:column:6="{row: {id}}">
            <Action icon="i-mdi:database-edit-outline" label="Edit measurements" v-on:action="gotoMeasure(id)"/>
        </template>
    </Table>
</template>
